<template>
  <div class="md-layout md-gutter zg-card" :class="`md-alignment-top-center`">
    <div class="md-layout-item md-size-30 zg-card-column-1">
      <SlotCopy :valueToCopy="businessEntityName">
        <span class="md-title">
          {{ businessEntityName | zbTruncate(50) }}
        </span>
      </SlotCopy>
      <SlotCopy :valueToCopy="businessEntityID">
        <span class="md-subtitle">Business ID:</span>
        <span class="md-body-1">
          {{ businessEntityID }}
        </span>
      </SlotCopy>
      <router-link target="_blank" :to="{ name: 'BusinessEntity', params: { businessEntityUuid: businessEntityUuid } }">
        View Entity
      </router-link>
    </div>
    <div class="md-layout-item zg-card-column-2">
      <md-list class="md-dense">
        <md-list-item v-for="dba in dbas" :key="dba.dba_entity_uuid" :class="`zg-list-height`">
          <span class="md-caption">DBA:&nbsp;</span>
          <div class="md-list-item-text">
            <span class="md-body-1">{{ dba.name }}</span>
          </div>
        </md-list-item>
        <md-list-item v-if="dbas.length === 0">
          <span class="md-caption">DBA:&nbsp;</span>
          <div class="md-list-item-text">
            <span class="md-body-1">None</span>
          </div>
        </md-list-item>
        <md-list-item v-for="fka in fkas" :key="fka.uuid" :class="`zg-list-height`">
          <span class="md-caption">FKA:&nbsp;</span>
          <div class="md-list-item-text">
            <span class="md-body-1">{{ fka.fka_name }}</span>
          </div>
        </md-list-item>
        <md-list-item v-if="fkas.length === 0">
          <span class="md-caption">FKA:&nbsp;</span>
          <div class="md-list-item-text">
            <span class="md-body-1">None</span>
          </div>
        </md-list-item>
      </md-list>
    </div>
    <div class="md-layout-item md-size-20">
      <span class="md-body-1">Jurisdiction:</span>
      &nbsp;
      <span class="md-body-2">{{ jurisdictionName }}</span>
      <template v-if="jurisdictionCode===office">
        &nbsp;
        <md-icon>check_circle_outline</md-icon>
      </template>
    </div>
    <div class="md-layout-item zg-card-column-4 md-size-20">
      <span class="md-body-1 has-danger-70-color" v-if="!productChecks && failedCheck !== false">
        <md-icon class="error-icon">warning</md-icon>
        We hit a snag. Try again.</span>
      <ZgButton v-if="!productChecks && failedCheck == false" @click.native="checkJurisdictionClick" size="sm" :isSubmitting="isSubmitting">
        Check subscriptions
      </ZgButton>
      <ZgButton v-else-if="!productChecks && failedCheck !== false" @click.native="checkJurisdictionClick" class="mt-1 rerun-check-buton" size="sm" :isSubmitting="isSubmitting">
        Rerun check
      </ZgButton>
      <md-list v-else class="md-dense">
        <template v-for="(nameService, service) of services">
          <md-list-item v-if="!getOrderTasksPerService(service)" v-bind:key="nameService">
            <span class="md-body-1">{{ nameService }}</span>
            <md-icon>cancel</md-icon>
          </md-list-item>
          <md-list-item v-else v-for="orderTask in getOrderTasksPerService(service)"
            :key="orderTask.order_task_uuid"
            :class="getClass(orderTask)"
            v-tippy="getVTippyInformation(orderTask)"
            >
          <span class="md-body-1">{{ nameService }}</span>
          <md-icon :class="getIconClass(orderTask)">{{getIcon(orderTask)}}</md-icon>
        </md-list-item>
        </template>
      </md-list>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { US_JURISDICTIONS_DICTIONARY } from '@/assets/jurisdictions';
import SlotCopy from '@/components/v2/common/Generics/SlotCopy';
import ZgButton from '@/components/v2/common/Generics/ZgButton';
import { textMixin, momentMixin } from '@/components/mixins';
import { capitalizeFirstLetter } from '@/assets/utils/text-utils';

export default {
  props: ['item', 'office'],
  components: {
    SlotCopy,
    ZgButton
  },
  data() {
    return {
      isSubmitting: false,
      failedCheck:  false,
      productChecks: undefined,
      services: {
        'annual_report_service': "Annual Report",
        'registered_agent_service': "Registered Agent",
        'virtual_business_address': "Virtual Mailing",
        'worry_free_service': "Worry Free",
        'anonymity': "Anonymity",
      }
    }
  },
  mixins: [textMixin, momentMixin],
  computed: {
    businessEntityName() {
      return this.item.business_entity.business_entity_name;
    },
    businessEntityID() {
      const entityID = this.item.jurisdiction_filing_number;
      return entityID?entityID:'None';
    },
    businessEntityUuid() {
      const UUID = this.item.business_entity.business_entity_uuid;
      return UUID;
    },
    jurisdictionCode() {
      const code = this.item.jurisdiction_code;
      return code;
    },
    jurisdictionName() {
      if(this.jurisdictionCode) {
        return US_JURISDICTIONS_DICTIONARY[this.jurisdictionCode];
      }
      return 'None'
    },
    jursidictionUuid() {
      const jurisdictionUuid = this.item.jurisdiction_uuid;
      return jurisdictionUuid;
    },
    dbas() {
      const dbas = this.item.dbas;
      return dbas;
    },
    fkas() {
      const fkas = this.item.business_entity_fkas;
      return fkas;
    },
  },
  methods: {
    ...mapActions({
      checkActiveOrderTaskPerJurisdiction: 'zebra/checkActiveOrderTaskPerJurisdiction',
    }),
    checkJurisdictionClick() {
      this.isSubmitting = true;
      this.productChecks = undefined;
      this.checkActiveOrderTaskPerJurisdiction({
        jurisdictionUuid: this.jursidictionUuid,
        serviceProducts: Object.keys(this.services)
      })
        .then((response) => {
          this.productChecks = response.results;
          this.failedCheck = false;
        })
        .catch(() => {
          this.failedCheck = true;
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    },
    getOrderTasksPerService(service) {
      if(this.productChecks) {
        return this.productChecks[service];
      }
      return null;
    },
    getCorrectState(orderTask) {
      if(orderTask) {
        if (orderTask.order_task_status === "active") {
          return true;
        }
        return false;
      }
      return null;
    },
    getVTippyInformation(orderTask) {
      if(this.getCorrectState(orderTask)!==null) {
        const status = orderTask.order_task_status;
        const displayStatus = capitalizeFirstLetter(status);
        const messages = [];
        if(status === 'canceled') {
          messages.push(`<b>Status:</b> ${displayStatus}`);
          messages.push(`Status of the Customer Asset as of ${this.toMoment(orderTask.status_updated_datetime, 'MM/DD/YYYY')}`);
        } else {
          messages.push(`<b>Status:</b> ${displayStatus}`);
        }
        const message = messages.join('<br>');
        const config = {
        'arrow': true,
        'allowHTML': true,
        'content': message
        };
        return config;
      }
      return {};
    },
    getIcon(orderTask) {
      if(this.productChecks) {
        const result = this.getCorrectState(orderTask);
        if(result===null) {
          return 'cancel';
        } else if (result===true) {
          return 'check_circle';
        } else if (result===false) {
          return 'remove_circle';
        }
      }
      return '';
    },
    getIconClass(orderTask) {
      if(this.productChecks) {
        const result = this.getCorrectState(orderTask);
        if(result===null) {
          return 'cancel';
        } else if (result===true) {
          return 'success-icon';
        } else if (result===false) {
          return 'error-icon';
        }
      }
      return '';
    },
    getClass(orderTask) {
      if(this.productChecks) {
        const result = this.getCorrectState(orderTask);
        if(result===null) {
          return 'zg-gray';
        } else if (result===true) {
          return 'zg-green';
        } else if (result===false) {
          return 'zg-red';
        }
      }
      return '';
    },
  },
  watch: {
    'item': function() {
      this.productChecks = undefined;
    }
  },
  created() {
    this.productChecks = undefined;
  }
}
</script>

<style lang="scss" scoped>

  .zg-card {
    min-height: 7em;
    padding: 1em 0em;
    border-bottom: 1px solid rgba(0,0,0,0.5);
  }
  .zg-card-column-1 {
    display: flex;
    flex-direction: column;
    a {
      color: var(--c-peace-40) !important;
      font-weight: bold;
      margin-top: 0.8rem;
    }
  }
  .zg-card-column-2 {
    .zg-list-height {
      min-height: 1.5em;
    }
  }
  .zg-card-column-4 {
    li.md-list-item {
      border-radius: 0.8em;
      margin: 0.1em;
    }
    .zg-green {
      background-color: var(--c-growth-10);
    }
    .zg-red {
      background-color: var(--c-energy-10);
    }
    .zg-gray {
      background-color: var(--c-focus-10);
    }
  }
  .rerun-check-buton{
    background-color: var(--c-danger-80)!important;
    width: 12.5rem;
  }
  .error-icon{
    color: var(--c-danger-80) !important;
  }
  .success-icon{
    color: var(--c-growth-20) !important;
  }
</style>
