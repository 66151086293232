<template>
  <div class="input-icons">
    <slot name="icon-left"></slot>
    <slot name="button-right"></slot>
    <span><input class="form-control zb-input" :maxLength="maxLength" v-model="text"></span>
  </div>

</template>

<script>
export default {
  name: 'ZgSearchInput',

  props: {
    value: String,
    maxLength: Number
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.zb-input {
  border: 1px solid var(--c-peace-50);
  border-radius: em(10, 20);
  font-size: 1.25rem;
  padding-left: 2.75rem !important;
  padding-top: 1.125rem !important;
  z-index: 1;
}

.input-icons {
  width: 100%;
  border: 0 !important;
}

.icon-left {
  position: absolute;
  margin-top: 1rem;
  padding: 0.625rem;
  min-width: 3.125rem;
  z-index: 2;
}

.icon-right {
  position: absolute;
  margin-top: 0.5rem;
  padding: 0.625rem;
  margin-left: 30.5rem;
  z-index: 10;
  pointer-events: auto;
}

span::before,
::placeholder {
  content: "Entity Name";
  font-size: .75rem;
  margin-top: 0.5rem;
  margin-left: 0.313rem;
  line-height: 1;
  box-sizing: border-box;
}

span {
  position: relative;
}

span::before {
  color: var(--c-focus-60);
  position: absolute;
  inset: 0 2.5rem;
  pointer-events: none;
}
</style>