<template>
    <md-button v-bind="{ ...computedAttrs }">
      <slot></slot>
      <!-- back compat -->
      <slot name="title"></slot>
      <transition name="zg-btn-thinking" v-if="isSubmitting" appear>
        <span class="zg-btn__icon">
          <md-progress-spinner class="md-accent" :md-diameter="20" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
        </span>
      </transition>
    </md-button>
  </template>
  
  <script>
  //import IconSpinner from '@/components/common/images/IconSpinner';
  export default {
    name: 'ZgButton',
    components: {
      //IconSpinner,
    },
    props: {
      color: {
        type: String,
        required: false,
        default: 'primary',
      },
      isSubmitting: {
        type: Boolean,
        default: false,
      },
      size: {
        type: String,
        required: false,
        default: '',
      },
      variant: {
        type: String,
        required: false,
        default: 'raised',
      },
    },
    computed: {
      computedAttrs() {
        const css = {
          class: {
            [`md-${this.color}`]: this.color,
            [`md-${this.variant}`]: this.variant,
            ['zg-btn']: true,
            [`zg-btn--size-${this.size}`]: this.size,
          },
        };
        if(this.isSubmitting) {
          delete css.class[`md-${this.color}`];
          css['disabled'] = true;
        }
        return css;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .zg-btn {
    border-radius: 0.875rem;
    box-shadow: none;
    font-size: 1.125rem;
    font-weight: 600;
    height: auto;
    line-height: 1.5;
    margin: 0;
    min-width: 0;
    padding: 1rem 1.5rem;
    text-transform: none;
    transition: background-color 300ms $easeInOutQuad, opacity 800ms $easeInOutQuad;
    &--size-lg {
      font-size: 16rem;
      line-height: 1.375rem;
      padding: 1.5rem 2rem;
    }
    &--size-sm {
      font-size: 1rem;
      line-height: 1.25;
      padding: 0.625rem 1.25rem;
    }
    // MUI Resets
    &.md-button::v-deep {
      &.md-raised {
        box-shadow: none;
      }
      .md-ripple {
        padding: 0;
      }
    }
    // Custom variant
    &.md-outline {
      outline: 1px solid currentColor;
    }
    // Custom colors
    &.md-danger {
      color: var(--c-danger-70);
    }
    &__icon {
      display: block;
      font-size: 1rem;
      line-height: 1;
    }
    &-thinking {
      &-enter {
        width: 0em;
        &-active {
          transition: width 400ms $easeOutExpo;
        }
        &-to {
          width: 1.5em;
        }
      }
      &-leave {
        width: 0em;
        &-active {
          transition: width 300ms $easeInExpo;
        }
        &-to {
          width: 0;
        }
      }
    }
  }
  </style>
  
  <style lang="scss">
  /**
   * Elements added by Vue Material do not appear to get a data attribute applied
   * in scoped styles. So, we need to use a global style to target the elements.
   */
  .zg-btn {
    .md-button-content {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 0.5rem;
      justify-content: center;
      text-transform: none;
    }
  }
</style>