<template>
  <div class="fullwidth">
    <vue-headful title="Zebra" />
    <DogWaldo :size=8></DogWaldo>
    <aside class="md-layout md-gutter" :class="`md-alignment-center-space-between`">
      <div class="md-layout-item" v-if="stateSearch !== undefined">
        You are in the <b>{{ statesDict[stateSearch] }}</b> office
      </div>
      <div class="md-layout-item zg-redirect-sos">
        <label for="stateSearch">Search Secretary of State in</label>
        <select id="stateSearch" v-model="stateSearch">
          <option :value="undefined">Choose one</option>
          <option
            v-for="(valueState, keyState) of RAStates"
            v-bind:key="keyState"
            :value="valueState"
          >
            {{ statesDict[valueState] }}
          </option>
        </select>
        <md-button class="md-icon-button" @click="goSOSSite" :disabled="stateSearch === undefined">
          <md-icon>arrow_circle_right</md-icon>
        </md-button>
      </div>
    </aside>
    <article>
      <header aria-label="Search Header">
        <span class="md-headline">Search Correspondence Subscriptions</span>
        <span class="md-subheading">
          Find out if clients have the correct active subscriptions to have us receive their mail.
        </span>
        <div class="row">
          <div class="col-10">
            <ZgSearchInput type="text" v-model="text" v-on:input="hasContent" :maxLength="60">
              <template v-slot:icon-left><md-icon class="icon-left">search</md-icon></template>
              <template v-if="text" v-slot:button-right>
                      <md-button class="md-icon-button icon-right" @click="resetText" >
                      <md-icon >close</md-icon>
                      </md-button>
              </template>
            </ZgSearchInput>
          </div>
          <div class="col-2">
          <ZgButton class="nav-link btn btn-link" :disabled="isDisabled" @click="searchText" :isSubmiting="true" type="submit">
            <template v-slot:title>Search</template>
          </ZgButton>
          </div>
        </div>


      </header>
      <section aria-label="Loading" v-show="numberSearchs>0">
        <div class="loading">
          <md-progress-spinner class="md-accent" :md-stroke="2" md-mode="indeterminate"></md-progress-spinner>
        </div>
      </section>
      <section aria-label="Search Results" v-show="results && results.length>0">
        <CardResult v-for="entity in results" v-bind:key="entity.business_entity_uuid"
          :item="entity"
          :office="stateSearch"
        />
      </section>
      <section aria-label="No Results" v-if="showNoResults">
        <div class="no-results">
          <span class="md-title">No results for '{{searchedText}}'</span>
          <span class="md-body-1">Try another search or click below to</span>
          <md-button class="md-dense" @click="goSOSSite" :disabled="stateSearch === undefined"
            >Search the Secretary of State in
            {{ statesDict[stateSearch] }}
          </md-button>
        </div>
      </section>
      <section aria-label="Load More" v-show="showLoadMore">
        <div class="load-more">
          <md-progress-spinner v-if="loadingMore" class="md-accent" :md-stroke="2" md-mode="indeterminate"></md-progress-spinner>
          <md-button v-if="!loadingMore" @click="loadMore">
            Load More
          </md-button>
        </div>
      </section>
    </article>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { US_JURISDICTIONS_DICTIONARY, US_JURISDICTIONS_RACO } from '@/assets/jurisdictions';
import {hasPermissionMixin} from '@/components/mixins'
import sossearch from '@/assets/sossearch';
import CardResult from './CardResult.vue';
import ZgSearchInput from '@/components/v2/common/Generics/ZgSearchInput.vue';
import ZgButton from '@/components/common/Generics/ZgButton.vue'
import DogWaldo from '@/components/developers/easter-eggs/dog-waldo.vue';
export default {
  name: 'ZebraView',
  components: {
    CardResult,
    ZgButton,
    ZgSearchInput,
    DogWaldo,
  },
  mixins: [hasPermissionMixin],
  data() {
    return {
      stateSearch: undefined,
      text: '',
      searchedText:'',
      numberSearchs: 0,
      firstSearch: true,
      isDisabled: true,
      loadingMore: false,
    }
  },
  computed: {
    ...mapGetters({
        results: 'zebra/getResults',
        getNext: 'zebra/getNext',
    }),
    RAStates() {
      return US_JURISDICTIONS_RACO;
    },
    statesDict() {
      return US_JURISDICTIONS_DICTIONARY;
    },
    showNoResults() {
      const isThereResults = this.results?.length>0;
      const isLoading = this.isLoading
      return !isThereResults && this.numberSearchs===0 && !isLoading && !this.firstSearch;
    },
    showLoadMore() {
      return this.getNext;
    },
  },
  methods: {
    ...mapActions({
      fetchResultsSearch: 'zebra/fetchResultsSearch',
      clearResults: 'zebra/clearResults'
    }),
    hasContent(){
      if (this.text.length > 0){
        this.isDisabled = false;
      }
    },
    resetText() {
      this.text = '';
      this.isDisabled = true;
      this.clearResults();
    },
    searchText() {
      let word = this.text
        this.numberSearchs += 1;
        this.isDisabled = true;
        this.firstSearch = false;
        this.fetchResultsSearch({word})
        .finally(() => {
          this.numberSearchs -= 1;
          this.isDisabled = false;
          this.searchedText = word;
        });

    },
    loadMore() {
      if (this.getNext) {
        this.loadingMore = true;
        this.fetchResultsSearch({
          word: this.text,
          page: this.getNext
        }).finally(()=>{
          this.loadingMore = false;
        });
      }
    },
    goSOSSite() {
      const state = this.RAStates.find((value) => { return value===this.stateSearch });
      window.open(sossearch[state], '_blank');
    },
    isInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  },
  created() {
    this.clearResults();
  },
  mounted () {
    //Detect when scrolled to bottom.
    const loadMoreDiv = document.querySelector('.load-more');
    window.addEventListener('scroll', () => {
      if(this.isInViewport(loadMoreDiv) && !this.loadingMore) {
        this.loadMore();
      }
    });
    
  },
}
</script>

<style lang="scss" scoped>
  aside {
    height: 3em;
    background-color: #CFF9FA;
    border-radius: 0 0 1rem 1rem;
    .zg-redirect-sos {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
      select {
        background-color: transparent;
        border: 0;
        border-bottom: 2px dotted #1C6EA4;
        &:focus-visible {
          outline: 0;
        }
      }
    }
  }
  article {
    display: flex;
    flex-direction: column;
    justify-content: center;
    header {
      margin: 2em 0 1em 0;
      align-self: center;

      display: flex;
      flex-direction: column;
      row-gap: 0.8em;
      .md-headline {
        font-weight: bolder;
        align-self: center;
      }
    }
    section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .no-results {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.5em;
        max-width: 80%;
        span, button {
          align-self: center;
        }
        button {
          color: var(--c-peace-40)
        }
      }
      .load-more {
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 0.5em;
        max-width: 80%;
        margin: 2em 0em;
        button {
          align-self: center;
          color: var(--c-peace-40)
        }
      }

      .loading {
        margin-top: 3em;
        align-self: center;
      }
    }
  }
  .top25{
    margin-top: 25px;
  }
</style>