export default {
  AK: 'https://www.commerce.alaska.gov/cbp/main/search/entities',
  AL: 'http://sos.alabama.gov/government-records/business-entity-records',
  AZ: 'https://ecorp.azcc.gov/EntitySearch/Index',
  AR: 'http://www.sos.arkansas.gov/corps/search_all.php',
  CA: 'https://bizfileonline.sos.ca.gov/search/business',
  CO: 'https://www.sos.state.co.us/biz/BusinessEntityCriteriaExt.do',
  CT: 'http://www.concord-sots.ct.gov/CONCORD/online?sn=InquiryServlet&eid=99',
  DE: 'https://icis.corp.delaware.gov/Ecorp/EntitySearch/NameSearch.aspx',
  FL: 'https://search.sunbiz.org/Inquiry/CorporationSearch/ByName',
  GA: 'https://ecorp.sos.ga.gov/BusinessSearch',
  HI: 'https://hbe.ehawaii.gov/documents/search.html',
  ID: 'https://sosbiz.idaho.gov/search/business',
  IL: 'https://apps.ilsos.gov/corporatellc/',
  IN: 'https://bsd.sos.in.gov/publicbusinesssearch',
  IA: 'http://sos.iowa.gov/search/business/(S(binnjbfju1oaz1btn0yvumj5))/search.aspx',
  KS: 'https://www.accesskansas.org/bess/flow/main?execution=e1s1',
  KY: 'https://web.sos.ky.gov/ftsearch/',
  LA: 'https://coraweb.sos.la.gov/CommercialSearch/CommercialSearch.aspx',
  ME: 'https://icrs.informe.org/nei-sos-icrs/ICRS?MainPage=x',
  MD: 'https://egov.maryland.gov/businessexpress/entitysearch',
  MA: 'http://corp.sec.state.ma.us/corpweb/corpsearch/CorpSearch.aspx',
  MI: 'https://cofs.lara.state.mi.us/SearchApi/Search/Search',
  MN: 'http://mblsportal.sos.state.mn.us/Business/Search',
  MS: 'https://corp.sos.ms.gov/corp/portal/c/page/corpBusinessIdSearch/portal.aspx?#clear=1',
  MO: 'https://bsd.sos.mo.gov/BusinessEntity/BESearch.aspx?SearchType=0',
  MT: 'http://sos.mt.gov/business',
  NE: 'https://www.nebraska.gov/sos/corp/corpsearch.cgi?nav=search',
  NV: 'https://www.nvsos.gov/sosentitysearch/',
  NH: 'https://quickstart.sos.nh.gov/online/BusinessInquire',
  NJ: 'https://www.njportal.com/DOR/BusinessNameSearch/Search/BusinessName',
  NM: 'https://portal.sos.state.nm.us/BFS/online/CorporationBusinessSearch',
  NY: 'https://apps.dos.ny.gov/publicInquiry/', 
  NC: 'https://www.sosnc.gov/online_services/search/by_title/_Business_Registration',
  ND: 'https://firststop.sos.nd.gov/search/business',
  OH: 'https://businesssearch.ohiosos.gov/#ExactNameDiv',
  OK: 'https://www.sos.ok.gov/business/corp/records.aspx',
  OR: 'http://egov.sos.state.or.us/br/pkg_web_name_srch_inq.login',
  PA: 'https://file.dos.pa.gov/search/business',
  RI: 'http://business.sos.ri.gov/CorpWeb/CorpSearch/CorpSearch.aspx',
  SC: 'https://businessfilings.sc.gov/BusinessFiling/Entity/Search',
  SD: 'https://sos.sd.gov/business/search.aspx',
  TN: 'http://tnbear.tn.gov/ECommerce/FilingSearch.aspx',
  TX: 'https://mycpa.cpa.state.tx.us/coa/',
  UT: 'https://secure.utah.gov/bes/index.html',
  VT: 'https://www.vtsosonline.com/online/BusinessInquire/',
  VA: 'https://cis.scc.virginia.gov/EntitySearch/Index',
  WA: 'http://www.sos.wa.gov/corps/corps_search.aspx',
  WV: 'https://apps.wv.gov/SOS/BusinessEntitySearch/',
  WI: 'https://www.wdfi.org/apps/CorpSearch/Search.aspx?',
  WY: 'https://wyobiz.wy.gov/Business/FilingSearch.aspx'
};
